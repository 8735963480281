  import React, { useState, useEffect } from 'react';









  import { CountdownCircleTimer } from 'react-countdown-circle-timer'



import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link,BrowserRouter } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);







  const [time, setTime] = useState(700);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array means useEffect runs once after the initial render



  return (
    <div>
      
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
        <ul>
          <li className='text-white'>যারা এই অ্যাপ থেকে প্রোডাক্ট কিনবেন তারাই এই লটারিতে অংশগ্রহণ করতে পারবে অটোমেটিক সফটওয়্যার তাকে নিয়ে নেবে !!</li>
        </ul>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>







            <BrowserRouter>

            
            <Link to='https://my-shopings.com'>my-shopings.com</Link>
            </BrowserRouter>
         <h6 className='text-info'> 
         এক্ষুনি শুরু হয়ে যাবে আর মাত্র বাকি আছে 
         
         
         
         <CountdownCircleTimer
         style={{textalin:'center'}}
    isPlaying
    duration={2628288}
    colors={['#004777', '#F7B801', '#A30000', '#A30000']}
    colorsTime={[7, 5, 2, 0]}
  >
    {({ remainingTime }) => remainingTime}
  </CountdownCircleTimer>

         
         সেকেন্ড এবং অর্ডার করুন দ্রুত পেয়ে যান লক্ষ লক্ষ টাকা পুরস্কার !!
         
         
           </h6>

         <ul>
          <li className='text-white'>লটারির জন্য এক্সট্রা কোন টাকা কাটবে না কোম্পানি 
            
          এবং আপনি পেতে 
            
             পারেন হাজার হাজার লক্ষ লক্ষ টাকা পুরস্কার এবং প্রতি মাসে লটারি ড্র হয় !</li>
         </ul>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
